import React, { Component } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { configureHeader, setListViewType } from "../../actions/ui";
import { Helmet } from "react-helmet";
import _get from "lodash/get";
import Footer from "../../components/global/footer/footer";
import UserTile from "../../components/global/tiles/user-tile";
import EventTile from "../../components/global/tiles/EventTile";
import { updateFilter } from "../../actions/update-filter";
import OptionSelect from "../../components/form/option-select";
import {
  CURATOR_TILE,
  STANDARD_TILE,
} from "../../constants/content-tile-types";
import { EVENT_STATE_PUBLISHED } from "@constants/config";
import { getAllCategoryFilters } from "../../utils/filter-utils";
import { getActiveLocations, currentLocation } from "../../selectors/filters";
import { stripHTML } from "../../utils/strings";
import SadFace from "@assets/image/sad-face.png";
import { META } from "../../constants/config";

class UserDetail extends Component {
  componentDidMount = () => {
    window && window.scrollTo(0, 0);
    this.props.configureHeader({
      headerVisibilityLocked: true,
      headerTheme: "white",
    });

    this.setViewType("grid");
  };

  setViewType = (type) => {
    this.props.setListViewType(type);
  };

  getRecommendedEvents = () => {
    const {
      events,
      user,
      auth: {
        me: { exist, data },
      },
    } = this.props;
    let recommendedEvents = user.recommendedEvents || [];
    if (exist) {
      const { id: meId, recommendedEvents: meRecommendedEvents } = data;
      if (String(meId) === String(user.id)) {
        recommendedEvents = meRecommendedEvents || [];
      }
    }
    return events
      .filter((event) => {
        return (
          event.event_state === EVENT_STATE_PUBLISHED &&
          recommendedEvents.includes(Number(event.id))
        );
      })
      .map((event, i) => {
        return (
          <EventTile
            key={String(event.id + i)}
            type={STANDARD_TILE}
            column="col s12 m6 xl4"
            categoryFilters={getAllCategoryFilters(this.props.filters)}
            {...event}
          />
        );
      });
  };

  onLocationChange = (e) => {
    this.props.updateFilter({
      type: "region",
      value: e.value,
      selected: true,
    });
  };

  getCurrentRegion = () => {
    const { currentRegion } = this.props;
    if (currentRegion) return currentRegion.value;
    return "select";
  };

  render() {
    const { user, me } = this.props;

    const { fullName, username, bio, shortBio, image, screenName } = user;

    const recommendedEvents = this.getRecommendedEvents();
    const metaDesciption = stripHTML(bio || shortBio);
    const isMe = _get(user, "id") === _get(me, "data.id", "");
    const myScreenName = isMe ? _get(me, "data.screenName", "") : screenName;

    return (
      <div className="user-detail">
        <Helmet>
          <title>{`${META.title} | Curator | ${fullName}`}</title>
          <link
            rel="canonical"
            href={`${process.env.GATSBY_TTF_SITE_URL}/${encodeURI(username)}`}
          />
          <meta name="description" content={metaDesciption} />
          <meta name="og:description" content={metaDesciption} />
          <meta
            property="og:title"
            content={`${META.title} | Curator | ${fullName}`}
          />
          {image && <meta property="og:image" content={image} />}
        </Helmet>

        <div className="container container__content">
          <div className="row">
            <div className="col s12 m3 col--curator">
              <div className="row">
                <UserTile
                  key="user-detail-info"
                  type={CURATOR_TILE}
                  showLongBio={true}
                  column="col s12"
                  user={user}
                  isMe={isMe}
                />
              </div>
            </div>
            <div className="col s12 m9 l8 xl9 col--recommended-events">
              {recommendedEvents.length > 0 ? (
                <span>
                  <div className="row">
                    <div className="col s12 heading heading--secondary">
                      <h2 className="heading__headline heading__headline--grey-bg">
                        <span>Recommended Events</span>
                      </h2>
                    </div>
                  </div>
                  <div className="row">{recommendedEvents}</div>
                </span>
              ) : (
                <div className="row">
                  <div className="col s12">
                    <div className="no-events__content">
                      <div className="no-events__graphic">
                        <img alt="" src={SadFace} />
                      </div>
                      <div className="no-events__headline">
                        {(myScreenName || fullName) && (
                          <span>
                            {`${
                              myScreenName || fullName
                            } HASN’T YET RECOMMENDED ANY EVENTS IN `}
                            <span>
                              <OptionSelect
                                editable={true}
                                required={false}
                                type="regionSelect"
                                name="region"
                                initialValue={this.getCurrentRegion()}
                                options={this.props.regions}
                                defaultOption={{
                                  value: "select",
                                  label: "Select",
                                }}
                                handleChange={this.onLocationChange}
                              />
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="no-events__discovery-cta">
                        {"Discover other events "}
                        <Link to="/events">here</Link>
                        {"."}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    me: state.auth.me,
    filters: state.eventFilters,
    regions: getActiveLocations(state),
    currentRegion: currentLocation(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
    updateFilter: (data) => {
      dispatch(updateFilter(data));
    },
    setListViewType: (type) => {
      dispatch(setListViewType(type));
    },
  };
};

const UserDetailWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetail);

export { UserDetail, UserDetailWrapper };
