import React, { Component } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { loadUser } from "@actions";
import Loading from "@components/global/loading-with-container";
import { UserDetailWrapper } from "@containers/user";

class User extends Component {
  componentDidMount = () => {
    const {
      slug: paramsSlug,
      user: { slug },
    } = this.props;

    if (paramsSlug !== slug) {
      this.props.getUser(paramsSlug);
    }
  };

  componentDidUpdate = () => {
    const {
      slug: paramsSlug,
      user: { slug },
    } = this.props;

    if (paramsSlug !== slug) {
      this.props.getUser(paramsSlug);
    }
  };

  render() {
    const {
      user: { checked, data, exist, loading, slug },
      events,
    } = this.props;

    const paramsSlug = this.props.slug;

    if (loading) {
      return <Loading size="100%" />;
    } else if (checked && !exist && slug === paramsSlug) {
      return <Redirect to={`/events`} />;
    } else if (exist) {
      return <UserDetailWrapper user={data} events={events} />;
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    slug: window.location.pathname.split("/")[2],
    events: state.events.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (slug) => {
      dispatch(loadUser(slug));
    },
  };
};

const UserRedux = connect(mapStateToProps, mapDispatchToProps)(User);

export default UserRedux;